import { ArrowForward, Share, Title } from '@mui/icons-material';
import { Box, BoxProps, Button, Stack, ThemeProvider, Typography } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { myHistory } from '../../common/base/myHistory';
import { CButton } from '../../common/components/CButton';
import { CLanguageSelect } from '../../common/components/CLanguageSelect';
import { lang, langM } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { w_theme } from '../theme/w_theme';

export const WPagePortal = memo((props: BoxProps) => {
   const [close, setClose] = useState(false);
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   useEffect(() => {
      const canvas = document.createElement('canvas');
      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.zIndex = '-1';
      document.body.appendChild(canvas);

      const ctx = canvas.getContext('2d');

      const resizeCanvas = () => {
         canvas.width = window.innerWidth;
         canvas.height = window.innerHeight;
      };

      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();

      const rects = Array.from({ length: 100 }, () => ({
         x: Math.random() * canvas.width,
         y: Math.random() * canvas.height,
         size: Math.random() * 20 + 10,
         dx: (Math.random() - 0.5) * 2,
         dy: (Math.random() - 0.5) * 2,
         ds: 0.1,
         angle: Math.random() * 360,
         da: (Math.random() - 0.5) * 2,
         color: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
            Math.random() * 256,
         )}, 0.5)`,
         delay: 0,
      }));

      let lastTime = 0;
      const fps = 60;
      const interval = 1000 / fps;

      const draw = (time: any) => {
         if (ctx === null) return;
         const deltaTime = time - lastTime;
         if (deltaTime > interval) {
            lastTime = time - (deltaTime % interval);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            rects.forEach((rect) => {
               if (rect.delay > 0) {
                  rect.delay -= 1;
                  return;
               }
               ctx.save();
               ctx.translate(rect.x + rect.size / 2, rect.y + rect.size / 2);
               ctx.rotate((rect.angle * Math.PI) / 180);
               ctx.fillStyle = rect.color;
               ctx.fillRect(-rect.size / 2, -rect.size / 2, rect.size, rect.size);
               ctx.restore();
               rect.x += rect.dx;
               rect.y += rect.dy;
               rect.size += rect.ds;
               rect.angle += rect.da;
               if (rect.size > 30 || rect.size < 10) rect.ds *= -1;
               if (rect.x < 0 || rect.x > canvas.width) rect.dx *= -1;
               if (rect.y < 0 || rect.y > canvas.height) rect.dy *= -1;
            });
         }
         requestAnimationFrame(draw);
      };

      // requestAnimationFrame(draw);

      return () => {
         window.removeEventListener('resize', resizeCanvas);
         document.body.removeChild(canvas);
      };
   }, []);

   return (
      <ThemeProvider theme={w_theme}>
         <Stack
            sx={{
               height: '100%',
               margin: 0,
               padding: 0,
               background: 'linear-gradient(to bottom, #88ccff 0px ,#ffffffcc 200px)',
            }}
         >
            <Stack
               sx={{
                  flex: 1,
                  overflow: 'hidden',
                  overflowY: 'auto',
                  textAlign: 'center',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
               }}
            >
               <Stack
                  width={'100%'}
                  maxWidth={800}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
               >
                  <Typography m={1} mt={2} ml={3} fontSize={18} fontWeight={'bold'} textAlign={'left'}>
                     {lang.w.안녕하세요마이월드메이커입니다}
                  </Typography>
                  <Box p={1} mt={1} mr={2}>
                     <CLanguageSelect />
                  </Box>
               </Stack>
               <Box p={1} />

               <Box
                  sx={{
                     // maxWidth: 900,
                     width: '100%',
                     maxWidth: 1000,
                     display: 'flex',
                     flexWrap: 'wrap',
                     flexDirection: 'row',
                     justifyContent: 'center',
                  }}
               >
                  {/* 마이월드메이커 */}
                  <Box
                     sx={{
                        width: 360,
                        height: 360,
                        direction: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        borderRadius: 0.8,
                        boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                        // maxwidth: 340,
                        m: 1,
                        p: 1,
                        position: 'relative',
                        overflow: 'hidden',
                        bgcolor: '#ffffff66',
                     }}
                  >
                     <Typography
                        sx={{
                           position: 'absolute',
                           left: 15,
                           top: 15,
                           fontSize: '0.9em',
                           opacity: 0.8,
                        }}
                     >
                        2023
                     </Typography>

                     {/* <Box height={40}>
                        <img
                           className="zoomInY"
                           src={`/logo2_1024x238_${langM.GetCurrentLanguage()}.png`}
                           alt={lang.w.제목}
                           width={150}
                           style={{
                              maxWidth: '100%',
                           }}
                        />
                     </Box> */}
                     <img
                        className="zoomInY"
                        src={`logo2_1024x238_${langM.GetCurrentLanguage()}.png`}
                        alt={lang.w.제목}
                        width={200}
                        style={{
                           maxWidth: '100%',
                           // position: 'absolute',
                           // top: 20,
                           // left: '50%',
                           // transform: 'translateX(-50%)',
                        }}
                     />
                     {/* <Box p={3} /> */}
                     {/* <WSubTitle sx={{ fontSize: '1em', p: 0 }}>{lang.w.개요.toUpperCase()}</WSubTitle> */}
                     <iframe
                        width="300"
                        height="170"
                        src="https://www.youtube.com/embed/ancfNZBkBek"
                        title="마이월드메이커로 집 만들기 Create a home with My World Maker"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                     ></iframe>
                     {/* <Box p={0.5} /> */}
                     <Typography className={'zoomIn'} m={1}>
                        {lang.w.어바웃01}
                     </Typography>
                     <Box p={0.5}>
                        <CButton
                           // className="heartBeat"
                           startIcon={<ArrowForward />}
                           onClick={() => {
                              window.open('/home');
                           }}
                        >
                           {lang.common.데모체험}
                        </CButton>
                     </Box>
                  </Box>

                  {/* 좀비좀비 */}
                  <Box
                     sx={{
                        width: 360,
                        height: 360,
                        direction: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        borderRadius: 0.8,
                        boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                        // maxwidth: 340,
                        m: 1,
                        p: 1,
                        position: 'relative',
                        overflow: 'hidden',
                        bgcolor: '#ffffff66',
                     }}
                  >
                     <Typography
                        sx={{
                           position: 'absolute',
                           left: 15,
                           top: 15,
                           fontSize: '0.9em',
                           opacity: 0.8,
                        }}
                     >
                        2025
                     </Typography>
                     <Box
                        sx={{
                           position: 'absolute',
                           top: 0,
                           left: 0,
                           width: '100%',
                           height: '100%',
                           backgroundImage: 'url(/zombiezombiesurvival_h.png)',
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           filter: 'blur(2px)',
                           opacity: 0.2,
                           zIndex: 1,
                        }}
                     />
                     <Box
                        sx={{
                           position: 'absolute',
                           top: 8,
                           right: 8,
                           zIndex: 3,
                        }}
                     >
                        <img
                           src={'/hot.png'}
                           alt={'hot'}
                           width={50}
                           style={{
                              maxWidth: '100%',
                           }}
                        />
                     </Box>
                     <Box sx={{ position: 'relative', zIndex: 2 }}>
                        <Typography height={40} sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                           {lang.etc.좀비좀비서바이벌이름}
                        </Typography>
                        {/* <img
                           className="zoomInY"
                           src={`zombiezombiesurvival_title_${langM.GetCurrentLanguage()}.png`}
                           alt={'zombiezombiesurvival_title_en.png'}
                           width={200}
                           style={{
                              maxWidth: '100%',
                              position: 'absolute',
                              top: -10,
                              left: '50%',
                              transform: 'translateX(-50%)',
                           }}
                        /> */}
                        <img
                           className="zoomInY"
                           src={`/zombiezombiesurvival_h.png`}
                           alt={'zombiezombiesurvival_h.png'}
                           width={310}
                           style={{
                              maxWidth: '100%',
                              marginTop: 0,
                           }}
                        />
                        <Typography className={'zoomIn'} m={1.5}>
                           {lang.etc.좀비좀비서바이벌설명}
                        </Typography>
                        <CButton
                           disabled={true}
                           // className="heartBeat"
                           startIcon={<ArrowForward />}
                           onClick={() => {
                              window.open('https://findfour.framer.ai/');
                           }}
                        >
                           {lang.common.준비중}
                        </CButton>
                     </Box>
                  </Box>

                  {/* 스카이 핑거 파이터 */}
                  <Box
                     sx={{
                        width: 360,
                        height: 360,
                        direction: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        borderRadius: 0.8,
                        boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                        // maxwidth: 340,
                        m: 1,
                        p: 1,
                        position: 'relative',
                        overflow: 'hidden',
                        bgcolor: '#ffffff66',
                     }}
                  >
                     <Typography
                        sx={{
                           position: 'absolute',
                           left: 15,
                           top: 15,
                           fontSize: '0.9em',
                           opacity: 0.8,
                        }}
                     >
                        2025
                     </Typography>
                     <Box
                        sx={{
                           position: 'absolute',
                           top: 0,
                           left: 0,
                           width: '100%',
                           height: '100%',
                           backgroundImage: 'url(/skyfingerfighter_h.png)',
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           filter: 'blur(2px)',
                           opacity: 0.2,
                           zIndex: 1,
                        }}
                     />
                     {/* <Box
                     sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 3,
                     }}
                  >
                     <img
                        src={'/hot.png'}
                        alt={'hot'}
                        width={50}
                        style={{
                           maxWidth: '100%',
                        }}
                     />
                  </Box> */}
                     <Box sx={{ position: 'relative', zIndex: 2 }}>
                        <Typography height={40} sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                           {lang.etc.스카이핑거파이터이름}
                        </Typography>
                        <img
                           className="zoomInY"
                           src={`/skyfingerfighter_h.png`}
                           alt={'skyfingerfighter_h.png'}
                           width={310}
                           style={{
                              maxWidth: '100%',
                           }}
                        />
                        <Typography className={'zoomIn'} m={1.5}>
                           {lang.etc.스카이핑거파이터설명}
                        </Typography>
                        <CButton
                           disabled={true}
                           // className="heartBeat"
                           startIcon={<ArrowForward />}
                           onClick={() => {
                              window.open('https://findfour.framer.ai/');
                           }}
                        >
                           {lang.common.준비중}
                        </CButton>
                     </Box>
                  </Box>

                  {/* 파인드포 */}
                  <Box
                     sx={{
                        width: 360,
                        height: 360,
                        direction: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        borderRadius: 0.8,
                        boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                        // maxwidth: 340,
                        m: 1,
                        p: 1,
                        position: 'relative',
                        overflow: 'hidden',
                        bgcolor: '#ffffff66',
                     }}
                  >
                     <Typography
                        sx={{
                           position: 'absolute',
                           left: 15,
                           top: 15,
                           fontSize: '0.9em',
                           opacity: 0.8,
                        }}
                     >
                        2024
                     </Typography>
                     <Box
                        sx={{
                           position: 'absolute',
                           top: 0,
                           left: 0,
                           width: '100%',
                           height: '100%',
                           backgroundImage: 'url(/findfour_h.jpg)',
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           filter: 'blur(2px)',
                           opacity: 0.2,
                           zIndex: 1,
                        }}
                     />
                     <Box sx={{ position: 'relative', zIndex: 2 }}>
                        {/* <img
                        className="zoomInY"
                        src={`/findfour_h.jpg`}
                        alt={'findfour_h.jpg'}
                        width={300}
                        style={{
                           maxWidth: '100%',
                        }}
                     /> */}
                        <Typography height={40} sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                           {lang.etc.파인드포이름}
                        </Typography>
                        <iframe
                           width="300"
                           height="170"
                           src="https://www.youtube.com/embed/9wV46_MWtgQ"
                           title="Find Four Intro Video"
                           frameBorder="0"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                           referrerPolicy="strict-origin-when-cross-origin"
                           allowFullScreen
                        ></iframe>
                        <Typography className={'zoomIn'} m={1.5}>
                           {lang.etc.파인드포설명}
                        </Typography>
                        <CButton
                           disabled={false}
                           // className="heartBeat"
                           startIcon={<ArrowForward />}
                           onClick={() => {
                              window.open('https://findfour.onelink.me/vurA/0tfteiuf');
                           }}
                        >
                           {lang.common.시작하기}
                        </CButton>
                        <span style={{ padding: 5 }}></span>
                        <CButton
                           disabled={false}
                           // className="heartBeat"
                           startIcon={<Share />}
                           onClick={() => {
                              // 폰에서 텍스트 공유하기
                              // if (MySystem.IsMobile()) {
                              if (navigator.share) {
                                 navigator
                                    .share({
                                       title: 'Find Four',
                                       text: 'Find Four',
                                       url: 'https://findfour.onelink.me/vurA/0tfteiuf',
                                    })
                                    .then(() => console.log('공유 성공'))
                                    .catch((error) => console.log('공유 실패', error));
                              } else {
                                 // alert('공유하기를 지원하지 않는 기기입니다.');
                              }
                              // }
                           }}
                        >
                           {lang.common.공유}
                        </CButton>

                        <Box pt={2}>
                           <span
                              style={{ padding: 3 }}
                              onClick={() => {
                                 window.open('https://onesto.re/0000773413');
                              }}
                           >
                              <img
                                 className="zoomInY"
                                 src={`store_onestore.svg`}
                                 alt={'store_onestore'}
                                 width={100}
                              />
                           </span>
                           <span
                              style={{ padding: 3 }}
                              onClick={() => {
                                 window.open(
                                    'https://play.google.com/store/apps/details?id=com.mwm.findfour.gg',
                                 );
                              }}
                           >
                              <img
                                 className="zoomInY"
                                 src={`store_googleplay.svg`}
                                 alt={'store_googleplay.jpg'}
                                 width={100}
                              />
                           </span>
                           <span
                              style={{ padding: 3 }}
                              onClick={() => {
                                 window.open(
                                    'https://apps.apple.com/us/app/find-four-find-4-differences/id6478101361',
                                 );
                              }}
                           >
                              <img
                                 className="zoomInY"
                                 src={`store_appstore.svg`}
                                 alt={'store_appstore.jpg'}
                                 width={100}
                              />
                           </span>
                        </Box>
                     </Box>
                  </Box>

                  {/* 이미지 생성기 */}
                  {/* <Stack
                  sx={{
                     width: 340,
                     height: 340,
                     direction: 'column',
                     justifyContent: 'center',
                     alignContent: 'center',
                     borderRadius: 2,
                     boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                     // maxwidth: 340,
                     m: 1,
                     p: 2.5,
                  }}
               >
                  <Box>
                     <img
                        className="zoomInY"
                        src={`/ai_h.jpg`}
                        alt={'ai'}
                        width={150}
                        style={{
                           maxWidth: '100%',
                        }}
                     />
                  </Box>
                  <Box className={'zoomIn'} m={1.5}>
                     {lang.etc.AI이미지생성기}
                  </Box>
                  <Box p={1}>
                     <CButton
                        disabled={true}
                        // className="heartBeat"
                        startIcon={<ArrowForward />}
                        onClick={() => {
                           window.open('https://findfour.framer.ai/');
                        }}
                     >
                        {lang.common.준비중}
                     </CButton>
                  </Box>
               </Stack> */}
               </Box>
               {/* 회사 소개 */}
               <Box m={1} />
               <Typography m={2} maxWidth={400}>
                  {lang.w.회사소개}
               </Typography>
               {/* 푸터 */}
               <Box m={2}>
                  <Box>
                     <img
                        height={30}
                        src={'/logo2_256x128.png'}
                        alt="logo"
                        style={{ position: 'relative', top: 1, margin: 5 }}
                        onClick={() => {
                           myHistory.push('/');
                           MySystem.ForceUpdate();
                        }}
                     />
                  </Box>
                  <Box>
                     <a href="https://www.hitwebcounter.com" target="_blank" rel="noreferrer">
                        <img
                           src="https://hitwebcounter.com/counter/counter.php?page=19715121&style=0006&nbdigits=7&type=ip&initCount=0"
                           title="Counter Widget"
                           alt="Visit counter For Websites"
                        />
                     </a>
                  </Box>
                  <Typography fontSize={'0.8em'}>
                     {lang.common.문의} :{' '}
                     <Box display={'inline-block'}>
                        <a href="mailto:contact@myworldmaker.com">contact@myworldmaker.com</a>
                     </Box>
                  </Typography>
                  <Typography width={'auto'} fontSize={'0.8em'}>
                     {lang.w.주소}
                  </Typography>
                  <Typography width={'auto'} fontSize={'0.8em'}>
                     {lang.w.사업자등록번호}
                  </Typography>
               </Box>
            </Stack>
         </Stack>
      </ThemeProvider>
   );
});
