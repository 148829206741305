import { Button, ButtonProps } from '@mui/material';
import { memo } from 'react';

// export const CButton = styled(Button)<ButtonProps>(({ theme: Theme }) => ({
//    // color: theme.palette.getContrastText('primary.main'),
//    backgroundColor: 'primary.main',
//    '&:hover': {
//       backgroundColor: purple[700],
//    },
//    borderRadius: 30,
// }));

// export const CButton = memo((props: ButtonProps) => {
//    let props2 = { ...props };
//    if (props) {
//       props2.color = props.color ?? 'primary';
//       props2.variant = props.variant ?? 'contained';
//       props2.style = props.style ?? {};
//       props2.style.borderRadius = (props.style?.borderRadius) ?? 30;
//    }
//    return <Button {...props2} />;
// });

export const CButton = memo((props: ButtonProps) => {
   // let theme = useTheme();
   return (
      <Button
         {...props}
         // sx={{
         //    ...props.sx,
         // color: theme.palette.primary.contrastText,
         // backgroundColor: 'primary.light',
         // }}
         // disabled={props.disabled ?? MyTaskM.HasTask()}
         variant={props.variant ?? 'contained'}
      >
         {/* {typeof props.children === 'string' ? (
            <Typography noWrap fontSize={'1em'}>{props.children}</Typography>
         ) : ( */}
         {props.children}
         {/* )} */}
      </Button>
   );
});
